import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactPlayer from 'react-player'
import Image from "next/image";
import SliderComponent from "./SliderWrap";
import { SwiperSlide } from 'swiper/react';



type TSlider = {
  is_video: boolean,
  media?: {
    data: {
      attributes: {
        url: string,
        name: string
      }
    }
  },
  video_url?: string,
  invert: boolean
}


type Props = {
  contents: TSlider[]
}

const ContentSlider = ({contents}: Props) => {


  // https://github.com/cookpete/react-player/issues/1428 : solve for Hydration failed
  const [hasWindow, setHasWindow] = useState(false);

  useEffect (() => {
    if (typeof window !== "undefined") {
      setHasWindow(true)
    }
  },[])

  const SlideImage = (content: TSlider) => {

    if ( content.media && content.media.data) {
      const _src = `${process.env.NEXT_STRAPI_API_URL }${content.media.data.attributes.url}`;
        return (
          <div className="content-item">
            <Image
              src={_src}
              layout="fill"
              alt={content.media.data.attributes.name}
              objectFit={'cover'}
              // width={1366}
              // height={856}
              sizes={"100vw"}
              placeholder="blur"
              blurDataURL={`data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII=`}
            />
          </div>
        )

    }
  }

  const SlideVideo = (content: TSlider) => {
      return (
        <div className="content-item has-video">
          <div className="player-wrapper">
            { hasWindow && 
              <ReactPlayer 
                className='react-player'
                width="100%"
                height="100%"
                url={`${content.video_url}`}
              /> 
            }
          </div>
        </div>
      )
  }

  return (
    <ContentSliderComp>
      <div className="inner">

        <SliderComponent id="content-slider" nav={true} initialSlide={0} playSpeed={2000} isFade={false} isAutoPlay={false}>
          {
            contents && contents.map((content:TSlider, key:number) => {

              return (
                <SwiperSlide key={key}>
                  { !content.is_video && SlideImage(content) }
                  { content.is_video && SlideVideo(content) }
                </SwiperSlide>
              )
            })
          }
        </SliderComponent>

      </div>
    </ContentSliderComp>
  )
}

export default ContentSlider;

const ContentSliderComp = styled.div`
  .content-item {
    min-height: calc(90vw / 330 * 380);
    position: relative;
  }

  .swiper-slide {
    width: 90vw;
  }

  .player-wrapper {
    width: auto; // Reset width
    height: auto; // Reset height
  }
  .react-player {
    padding-top: calc(90vw / 330 * 380); // Percentage ratio for 330:380
    position: relative; // Set to relative
  }

  .react-player > div {
    position: absolute; // Scaling will occur since parent is relative now
    top: 0;
    left: 0;
  }

  .sliderWrap__navigation {
    display: none;
  }

  @media screen and (min-width: ${({theme}) => theme.breakPoints.tablet}) {
    .content-item {
      min-height: calc(90vw / 3 * 2);
      position: relative;
    }

    .react-player {
      padding-top: calc(90vw / 3 * 2); // Percentage ratio for 3:2
    }
  }

  @media screen and (min-width: ${({theme}) => theme.breakPoints.desktop}) {
    min-height: 50vh;

    .sliderWrap__navigation {
      display: block;
      top: calc(50% - 17px);
      left: calc(90vw - 47px);
    }

  }

`